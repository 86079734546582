<template>
  <div class="auth-management">
    <div class="m-title">开票申请</div>
    <el-card>
      <!-- @selection-change: 当选择项发生变化时会触发该事件 selection -->
      <!-- @select:当用户手动勾选数据行的 Checkbox 时触发的事件 selection, row-->

      <el-table
        ref="orderData"
        :data="orderData"
        :header-cell-style="{
          'text-align': 'center',
          background: '#f2f2f2',
          color: '#838a96',
          border: 'none',
        }"
        :cell-style="{ 'text-align': 'center', color: '#000' }"
        style="width: 100%"
        :row-key="
          (row) => {
            return row.id;
          }
        "
        @selection-change="handleSelectionChange"
        @select="select"
        v-loading="loading"
      >
        <!-- reserve-selection="true",仅对 type=selection 的列有效，类型为
        Boolean，为 true 则会在数据更新之后保留之前选中的数据（需指定 row-key） -->
        <el-table-column
          type="selection"
          width="55"
          :selectable="selectable"
          :reserve-selection="true"
        >
        </el-table-column>
        <el-table-column prop="id" label="ID" width="60px"> </el-table-column>
        <el-table-column prop="order_num" label="订单号" width="200px">
        </el-table-column>
        <el-table-column prop="status" label="订单状态">
          <template slot-scope="scope">
            <div>
              {{ scope.row.status | orderStatus }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="pay_type" label="支付方式">
          <template slot-scope="scope">
            <el-tag :type="scope.row.pay_type == 1 ? 'success' : 'primary'">
              {{ scope.row.pay_type | payTypeStatus }}</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column prop="invoicing" label="开票状态">
          <template slot-scope="scope">
            <el-tag
              :type="
                scope.row.invoicing == '0'
                  ? 'primary'
                  : scope.row.invoicing == '1'
                  ? 'danger'
                  : 'success'
              "
              >{{
                scope.row.invoicing == "0"
                  ? "未开票"
                  : scope.row.invoicing == "1"
                  ? "已申请"
                  : "已开票"
              }}</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column prop="amount" label="金额">
          <template slot-scope="scope">
            <div style="color: #ff7125">{{ scope.row.amount }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="add_time" label="时间" width="150px">
          <template slot-scope="scope">
            {{ scope.row.add_time }}
          </template>
        </el-table-column>
      </el-table>
      <el-row class="m-all">
        <el-col :span="8" style="padding-left: 20px">
          <el-checkbox v-model="allCheck" @change="allCheckEvent"
            >全选</el-checkbox
          >
        </el-col>
        <el-col :span="12" style="text-align: right; padding-right: 30px">
          <span>
            已选择
            <strong>{{ selectedNum }}</strong>
            项
          </span>
          <span style="margin-left: 10px">
            开票总金额：<strong>{{ totalPrice }}.00</strong>元
          </span>
        </el-col>
        <el-col :span="4" style="text-align: center">
          <el-button size="small" type="primary" @click="applyInvoice()"
            >申请开票</el-button
          >
        </el-col>
      </el-row>
      <!-- 分页 -->
      <el-row class="pagination">
        <Pagination
          :page.sync="page"
          :limit.sync="limit"
          :total="orderCount"
          @getList="getOrderList"
        />
      </el-row>
    </el-card>
  </div>
</template>

<script>
  import {
    getOrderListApi,
    applyInvoiceApi
  } from '@/api/user'
  import Pagination from '@/components/Pagination/index.vue'
  export default {
    name: 'applyInvoice',
    components: {
      Pagination
    },
    data() {
      return {
        orderData: [],
        loading: false,
        page: 1,
        limit: 10,
        orderCount: 0,
        allCheck: false, //全部选择框的选择与否
        checkList: [], //被选择的,
        selectOff: [] //不被选择的
      }
    },
    filters: {
      orderStatus(val) {
        let msg = ""
        switch (val) {
          case 1:
            msg = "已生成";
            break;
          case 2:
            msg = "已取消";
            break;
          case 3:
            msg = "已作废";
            break;
          case 4:
            msg = "已完成";
            break;
          case 5:
            msg = "已退款";
            break;
        }
        return msg
      },
      payTypeStatus(val) {
        let msg = ""
        switch (val) {
          case 1:
            msg = "微信支付";
            break;
          case 2:
            msg = "支付宝支付";
            break;
        }
        return msg
      }
    },
    computed: {
      selectedNum() {
        let result = 0
        result = this.checkList.length
        return result
      },
      totalPrice() {
        let result = 0
        for (let i = 0; i < this.checkList.length; i++) {
          result += Number(this.checkList[i].amount)
        }
        return result
      }
    },
    watch: {
      //监听渲染列表数组的变化，一旦变化 执行相关函数，使全选状态下，每一页按照全选显示
      orderData: {
        handler(value) {
          if (this.allCheck) {
            let that = this;
            let len = that.checkList.length;
            let lenOff = that.selectOff.length;
            value.forEach(row => {
              // 遍历新请求回来的当页数据，和已经选择的数组中所有对象对比，如果有相同的，说明已经选择了，不再选择，其他没有选择的设置为选中状态
              for (let i = 0; i < len; i++) {
                if (row.id === that.checkList[i].id) {
                  that.$refs.orderData.toggleRowSelection(row, false);
                  break;
                } else {
                  that.$refs.orderData.toggleRowSelection(row, true);
                }
              }
              // 循环遍历新的请求回来的当页数据，和手动未选择的数组对比，如果有相同的，将其样式显示为未选择状态
              for (let i = 0; i < lenOff; i++) {
                if (row.id === that.selectOff[i].id) {
                  that.$refs.orderData.toggleRowSelection(row, false);
                }
              }
            });
          }
        },
        deep: true
      }
    },
    created() {
      this.getOrderList()
    },
    methods: {
      //订单列表信息
      async getOrderList() {
        this.loading = true
        const {
          data: res
        } = await getOrderListApi(this.page, this.limit)
        if (res.status != 10000) {
          return this.$message.error(res.message)
        }
        this.orderData = res.result.list
        this.orderCount = res.result.count
        this.loading = false
      },
      //复选框选择全选 
      //toggleRowSelection:用于多选表格，切换某一行的选中状态，如果使用了第二个参数，则是设置这一行选中与否（selected 为 true 
      //clearSelection:用于多选表格，清空用户的选择
      allCheckEvent() { // 1.1当选择所有 选项框状态为 √ 时
        if (this.allCheck) {
          this.orderData.forEach(row => {
            this.$refs.orderData.toggleAllSelection()
          })
          // 1.2当选择所有 选项框状态为 空 时
        } else {
          // 1.2.1遍历当前展示的数组，使所有的多选框为 未 选择状态
          this.$refs.orderData.clearSelection();
        }
      },
      //2.当选择项发生变化时会触发该事件
      handleSelectionChange(val) {
        this.checkList = val
      },
      //3.当用户手动勾选数据行的 Checkbox 时触发的事件
      select(selection, row) {
        if (this.allCheck) {
          let selected = selection.length && selection.indexOf(row) !== -1; //为true时选中，为 0 时（false）未选中
          // 当手动操作为未选中状态时
          if (!selected) {
            // 将未选中的数据放在一个变量里
            this.selectOff.push(row);
            // 当再次手动操作为选中状态的时候
          } else {
            // 为了使再次选中后，将未选择数组中的该数据删除掉，遍历未选择的数组 和当前操作打√的数据比较，如果不同的就过滤留下，相同的就去除。
            this.selectOff = this.selectOff.filter(
              // 如果item.score不等于row.score就留下
              item => item.id !== row.id
            );
          }
        } else {
          this.selectOff = []
        }
      },
      //申请开票
       applyInvoice() {
        let orderIdArr = []
        orderIdArr=this.checkList.map(item => {
          return item.id
        })
        if(!orderIdArr.length){
            return this.$message.error('暂无选择开票项')
        }
        this.$confirm("此操作将申请开票, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async()=>{
        const {
          data: res
        } = await applyInvoiceApi({
          id: orderIdArr
        })
        if (res.status != 10000) {
          this.$message.error(res.message)
        }
        this.$message.success('已提交开票申请')
        this.cancelSelection()
        this.getOrderList()
      }).catch(()=>{
        this.$message({
            type:'info',
            message:'已取消操作'
        })
      })
      },
      //根据invoicing条件判断是不禁用复选框
      selectable(row) {
        if (row.invoicing == 0) {
          return true
        } else {
          return false
        }
      },
      //4.取消选择所有数据
      cancelSelection() {
      this.$refs.orderData.clearSelection();
      this.allCheck = false;
    },
    },
  }

</script>

<style lang="less" scoped>
.auth-management {
  .m-title {
    height: 40px;
    line-height: 40px;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 20px;
    border-bottom: 1px solid #e4e7ed;
    text-align: center;
  }

  .m-all {
    height: 50px;
    line-height: 50px;
    border: 1px solid #e4e7ed;
    margin: 10px 0px;
    font-size: 14px;

    strong {
      font-size: 15px;
      font-weight: 600;
      color: #ff7125;
    }
  }

  /deep/ .el-table__header-wrapper .el-checkbox {
    display: none;
  }

  .pagination {
    margin-top: 20px;
    text-align: right;
  }
}
</style>
